import { FavoriteAppData } from '../../../types';

class FavoriteAppStore {
  favoriteApps: FavoriteAppData[];

  constructor() {
    this.favoriteApps = [];
  }

  getFavoritesApps(): FavoriteAppData[] {
    return this.favoriteApps;
  }

  setFavoriteApps(Favorites: FavoriteAppData[]) {
    this.favoriteApps = Favorites;
  }
}

const favoriteAppStore = new FavoriteAppStore();
export default favoriteAppStore;
