import { AppKey } from '@diligentcorp/atlas-web-component-global-nav/lib/configuration/types';
export enum FavoriteCardComponentType {
  Default = 'default',
  Draggable = 'draggable',
  Skeleton = 'skeleton',
  View = 'view',
}

export interface FavoriteCardProps {
  favoriteAppData: FavoriteAppData;
  onFavoriteButtonClick: (appData: FavoriteAppData) => void;
  componentType: FavoriteCardComponentType;
  isFavorite?: boolean;
}

export interface FavoriteAppData {
  appName: string;
  appKey: AppKey;
  sectionName: string;
  appLink: string;
  sortOrder?: number;
}

export interface ShowComponentElements {
  showDragKnob: boolean;
  showFavoriteButton: boolean;
}

export interface PaginationProps {
  FavoriteAppsData: FavoriteAppData[];
}

export interface PaginationDotIconProps {
  isActive: boolean;
}
