import React from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Box } from '@mui/material';
import { SortableContainer } from './Sortable.style';

const Sortable = ({
  children,
  onChange,
}: {
  children: React.ReactNode;
  onChange: (source: number, destination: number | null) => void;
}): JSX.Element => {
  const onDragEnd = (result: DropResult): void => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    onChange(result.source.index, result.destination.index);
  };
  const validChildren = filterChildren(children);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot): JSX.Element => (
          <Box {...provided.droppableProps} ref={provided.innerRef} data-testid="favorite-sortable-apps">
            {validChildren.map((child, index) => (
              <Draggable draggableId={`draggable-${index}`} index={index}>
                {(provided, snapshot) => (
                  <SortableContainer
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    data-is-dragging={snapshot.isDragging ? true : undefined}
                    ref={provided.innerRef}
                  >
                    {child}
                  </SortableContainer>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};
const SortableElement = ({ children, sortID }: { children: React.ReactNode; sortID: number }): JSX.Element => {
  return <>{children}</>;
};
SortableElement.displayName = 'SortableElement';
Sortable.sortableElement = SortableElement;
export default Sortable;

function filterChildren(children: React.ReactNode) {
  return React.Children.toArray(children).filter((child) => {
    if (typeof child === 'object' && child !== null && 'type' in child) {
      return (child.type as React.ComponentType).displayName === SortableElement.displayName;
    }
    return false;
  });
}
