// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0_esbuild@0.18.20_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0_esbuild@0.18.20_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-tile {
  border: 1px solid #dadada !important;
  cursor: pointer;
}

.section-title [highlighted-text] {
  color: #d3222a;
}
`, "",{"version":3,"sources":["webpack://./src/components/SectionTabs/SectionTabs.scss"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".button-tile {\n  border: 1px solid #dadada !important;\n  cursor: pointer;\n}\n\n.section-title [highlighted-text] {\n  color: #d3222a;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
