import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { App, GlobalNav } from '../../types';
import keyBy from 'lodash.keyby';
import { filterAppNavigation } from '@diligentcorp/atlas-web-component-global-nav/lib/configuration/utils';
import { APPS } from '@diligentcorp/atlas-web-component-global-nav/lib/configuration/constants';
import { AppKey, NavItem, SectionKey } from '@diligentcorp/atlas-web-component-global-nav/lib/configuration/types';

export type ProcessedApp = NavItem<AppKey, unknown> & Partial<App>;

export type Section = {
  key: SectionKey;
  apps: ProcessedApp[];
};

export interface FavoriteAppData {
  appName: string;
  appKey: AppKey;
  sectionName: string;
  appLink: string;
  sortOrder?: number;
}

export interface CombinedData {
  globalNavData: GlobalNav;
  processedData: Section[];
  hasAnyApp: boolean;
  favoriteData: FavoriteAppData[];
  isGlobalNavDataFetched: boolean;
}

export function mergeGlobalNavData({
  processedData,
  visibleApps,
}: {
  processedData: Section[];
  visibleApps: Record<string, App>;
}) {
  processedData.forEach((sectionData: Section) => {
    const processedApps = sectionData.apps.map((appPresentationData) => {
      if (!visibleApps[appPresentationData.key]) return null;
      return {
        ...appPresentationData,
        id: visibleApps[appPresentationData.key].id,
        title: visibleApps[appPresentationData.key].title,
        url: visibleApps[appPresentationData.key].url,
      };
    });
    sectionData.apps = processedApps.filter((app: ProcessedApp | null) => !!app) as ProcessedApp[];
  });
}

export function getCombinedData(globalNavData: GlobalNav) {
  const isGlobalNavDataFetched = !!globalNavData;
  const hasAnyApp = globalNavData?.appSwitcherProps?.apps?.length > 0;
  //mocking as the global nav data is not available in the api
  const favoriteApps = JSON.parse(localStorage.getItem('favoriteApps') || '[]');
  if (!isGlobalNavDataFetched || !hasAnyApp) {
    return {
      globalNavData,
      processedData: [],
      hasAnyApp,
      favoriteData: [],
      isGlobalNavDataFetched,
    };
  }
  const visibleApps = keyBy(globalNavData.appSwitcherProps.apps, 'key');
  const filteredApps = filterAppNavigation(Object.keys(visibleApps) as any);
  let processedData = Object.entries(filteredApps)
    .map((x) => x)
    .map((x) => {
      return { key: x[0] as SectionKey, apps: x[1] as ProcessedApp[] };
    });
  // adding resources tab to landing page alone
  processedData.push({ key: 'resources' as SectionKey, apps: [APPS.INSTALLERS] as ProcessedApp[] });
  mergeGlobalNavData({ processedData, visibleApps });
  // Filter apps based on favoriteApps

  const favoriteData = favoriteApps
    .map((favoriteApp: any) => {
      const section = processedData.find((section) => section.key === favoriteApp.sectionkey);
      if (section) {
        const app = section.apps.find((app) => app.key === favoriteApp.appkey);
        if (app) {
          return {
            appName: app.title,
            appKey: app.key,
            sectionName: favoriteApp.sectionkey,
            appLink: app.url,
            sortOrder: favoriteApp.appSortOrder,
          };
        }
      }
      return null;
    })
    .filter(Boolean); // Filter out null values
  return {
    globalNavData,
    processedData,
    hasAnyApp,
    favoriteData,
    isGlobalNavDataFetched,
  };
}

export function useCombinedGlobalNavData(globalNavData: GlobalNav): UseQueryResult<CombinedData> {
  return useQuery(
    ['combined_global_nav_data'],
    () => {
      return {};
    },
    {
      select: () => {
        return getCombinedData(globalNavData);
      },
    },
  );
}
