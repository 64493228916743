import { Box, IconButton, useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { PaginationDotIconProps } from '../../../types';

export const PaginationContainer = styled(Box)(() => {
  const { tokens } = useTheme();

  return {
    borderRadius: tokens.core.spacing['1_5'].value,
    backgroundColor: tokens.semantic.color.background.container.value,
    backgroud: tokens.semantic.color.background.base.value,
    padding: tokens.core.spacing['1_5'].value,
  };
});

export const CarouselItemContainer = styled(Box)(() => {
  return {
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
  };
});

export const PaginationButtonContainer = styled(Box)(() => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
  };
});

export const PaginationDotsIconContainer = styled(Box)(() => {
  const { tokens } = useTheme();

  return {
    display: 'flex',
    justifyContent: 'center',
    columnGap: tokens.core.spacing['1'].value,
    alignItems: 'center',
  };
});

export const CarouselContainer = styled(Box)(() => {
  const { tokens } = useTheme();

  return {
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    scrollBehavior: 'smooth',
    columnGap: tokens.core.spacing['2'].value,
    alignItems: 'center',
    paddingBottom: tokens.core.spacing['1_5'].value,
    alignSelf: 'stretch',
  };
});

export const DotIcon = styled(IconButton)<PaginationDotIconProps>(({ isActive }) => {
  const { tokens, palette } = useTheme();

  return {
    height: 0,
    width: 0,
    flex: '0 0 auto',
    color: isActive ? tokens.semantic.color.status.new.value : palette.grey[500],
    backgroundColor: 'unset',

    ':focus': {
      outlineStyle: 'unset',
      backgroundColor: 'unset',
    },
    ':not(.Mui-disabled):hover': {
      background: 'unset',
      color: tokens.semantic.color.status.new.value,
    },
    ':over': {
      outlineStyle: 'unset',
      backgroundColor: 'transparent',
      color: tokens.semantic.color.status.new.value,
    },
  };
});
